
import { FC, useContext } from 'react'
import { FormikConsumer } from 'formik'

import { Col, Button, Text } from '@mch-group/uikit-components'
import { Translations as TranslationsService } from '@services'

import ORLabel from './common/OrLabel'
import { useUserAccessContext } from '../context/useUserAccessContext'
import { PageContext } from '@providers/PageContext'

interface ILoginWithOrWithoutPassword {
  userFirstName: string,
  sendMagicLinkForLogin: Function,
  submitUserLoginDetail: Function
}

const LoginWithOrWithoutPasswordStep: FC<ILoginWithOrWithoutPassword> = ({
  userFirstName,
  sendMagicLinkForLogin,
  submitUserLoginDetail
}) => {
  const {
    state: { isLoading, apiErrorMessage, labels: userAccessLabels, otherData },
    dispatch
  } = useUserAccessContext()
  const { page: { siteLanguage } } = useContext(PageContext)
  return (
    <FormikConsumer>
      {({ handleBlur, values, errors, touched, handleChange, setFieldValue }) => (
        <Col className={!otherData.isModal ? 'mt-0' : ''}>
          {!otherData.isModal && <h4 className='pb-9'>{userAccessLabels.Access.loginHeadingTextLabel}</h4>}
          <h5 className='pt-2'>
            {userAccessLabels.Access.helloLabel} {userFirstName ? userFirstName : ''}
          </h5>
          <p className='text-medium mt-3'>
            {userAccessLabels.Access.passwordIssueDesc}
          </p>
          <div className='d-grid mt-8'>
            {apiErrorMessage && values.loginWithoutPassword &&
              <span className='text-danger form-text mb-4'>{apiErrorMessage}</span>
            }
            <Button
              type='button'
              className='next-button'
              onClick={async () => {
                const emailSentLabel = await TranslationsService.getDynamicLabelValue(
                  'Access',
                  'emailSentInviteText',
                  siteLanguage,
                  `${values.email},2`
                )
                setFieldValue('loginWithoutPassword', true)
                // @ts-ignore
                setFieldValue('emailSentInviteText', emailSentLabel?.data?.value || 'Email sent')
                sendMagicLinkForLogin(values)
              }}
              isLoading={values.loginWithoutPassword && isLoading}
            >
              {userAccessLabels.Access.noPasswordLoginLabel}
            </Button>
          </div>
          <ORLabel orLabel={userAccessLabels.Access.orLabel} className='mt-6 mb-5' />
          <p className='text-medium'>
            {userAccessLabels.Access.enterPassword}
          </p>
          <Text
            label={userAccessLabels.Access.passwordLabel}
            type='password'
            value={values.password}
            onChange={(e) => {
              handleChange(e)
              dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: '' })
            }}
            onBlur={handleBlur}
            id='password'
            // @ts-ignore
            helpText={!values.loginWithoutPassword && ((touched.password && errors.password) || apiErrorMessage)}
            helpTextType={!values.loginWithoutPassword && ((touched.password && errors.password) || apiErrorMessage) ? 'danger' : 'muted'}
          />
          <div className='d-grid mt-8'>
            <Button
              type='submit'
              variant='secondary'
              onClick={() => {
                if (values.password && !errors.password) {
                  setFieldValue('loginWithoutPassword', false)
                  submitUserLoginDetail({ username: values.email, password: values.password }, false)
                }
                if (!values.password) {
                  dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: userAccessLabels.Access.fieldRequiredLabel })
                }
              }}
              isLoading={isLoading && !values.loginWithoutPassword}
            >
              {userAccessLabels.Access.loginButtonLabel}
            </Button>
          </div>
        </Col>
      )}
    </FormikConsumer >
  )
}

export default LoginWithOrWithoutPasswordStep
